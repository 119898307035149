@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dancingscript/v23/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.bg_img{
    background-image: url("../../public/img/bghome.jpg");
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter:grayscale(100%) brightness(20%) ;
    z-index: -1;
}

.home_text{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    color: var(--text-color);
    justify-content: center;
    align-items: center;
}

.big_text{
    font-size: 3rem;
}

span.typed{
	font-family: 'Alien Robot', 'arial';
    display: inline-block;
    font-size: 3em;
    height: 30px;
}

.cardBodyLeft{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px;
    width:70vw;
}
.cardBodyRight{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px;
    width:100%;
    >p{
        line-height: 2em;
    }
}

.cardBody_top{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.cardBody_bottom{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

img{
    display: block;
    height: 170px;
    width: 170px;
    border-radius: 7px;
}

.photo{
    position: relative;
    padding: 0 0 0 30px;
}
.img_overlay{
    position: absolute;
    bottom: 100%;
    left: 30px;
    right: 0;
    background-color: #000;
    filter: grayscale(50%) opacity(70%);
    overflow: hidden;
    height: 0px;
    width: 0px;
    border-radius: 7px;
    transition: .5s ease;
        >p{
        font-family: 'Alien Robot';
        white-space: nowrap; 
        color: white;
        font-size: 50px;
        position: absolute;
        overflow: hidden;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
}
.photo:hover .img_overlay{
    bottom: 0;
    height: 170px;
    left:30px;
    width:170px;
}

.me{
    padding-left: 30px;
    line-height: 1.3em;
}

.stack{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding-top: 60px;
}


.p_card{
    position: relative;
    text-decoration: none;
    box-shadow: 0 -3px 10px black;
    margin: 10px;
    width: 300px;
    height: 285px;
    background-color: transparent;
    overflow: hidden;
    transition: all 1s;
    >.txt_overlay{
        font-family: 'Alien Robot';
        font-size: auto;
        font-weight: bold;
        position: absolute;
        margin-top:100%;
        padding: 8px 0 0 0;
        text-align: center;
        height: 33px;
        color: #000;
        width: 100%;
        background-color: white;
        transition: all 0.5s ease-in-out 0s;
        z-index: 2;
    }
    >.card_desc{
        font-size: small;
        color: black;
        padding: 0 10px 0 10px;
        max-height: 29px;
        min-height: 29px;

    }
    >img{
        border-radius:0px;
        width: 300px;
        transition: all 1s;
        border-bottom: 1px solid black;
    }
    >.bottom{
        height: 7px;
        background-color: var(--orange);
        transition: all 1s;
    }
    .site_work{
        margin: 0;
        font-size: 0.7em;
        padding: 3px 7px 3px 7px;
        margin: 10px 0 0 10px;
        width: fit-content;
        color: white;
        background-color: var(--orange);
        border-radius: 10px;
        transition: all 1s;
    }
    >.site_date{
        text-align: right;
        margin: 10px;
        font-size: 0.7em;
        transition: all 1s;
        color: #000;
    }
    >.card_desc{
        font-size: small;
        color: #000;
        padding: 0 10px 0 10px;
    }
}

.p_card:hover{
    box-shadow: 0 0 10px black;
    overflow: hidden;
    >.txt_overlay{
        margin: 0px;
        transition: all 0.8s ease-in-out 0.8s;
    }
    >img{
        border-bottom: none;
        transform: scale(2.5);
        filter: brightness(0.4);
    }
    >.bottom{
        margin-left: 100%;
    }
    .site_work{
        margin-left: 70%;
    }
    >.site_date{
        margin-right: 70%;
    }
}

@media (max-width: 992px) {
    
}

@media (max-width: 768px) {
    .big_text{
        font-size: 2rem;
    }
    span.typed{
        font-size: 2em;
    }
    .cardBodyLeft{
        flex-wrap: wrap;
        width: 100%;
    }
    img{
        margin-left: auto;
        margin-right: auto;
    }
    .me{
        padding:none;
    }
    .cardBodyRight{
        flex-wrap: wrap;
    }
}



@media (max-width: 576px) {
    .big_text{
        font-size: 1.5rem;
    }
    span.typed{
        font-size: 1.3em;
    }
    .cardBody_top{
        flex-wrap: wrap;
    }
    .me{
        padding-left: none;
    }
}
