.stack_card{
    display: flex;
    flex-direction: column;
    height: 50vh;
    position: sticky;
    margin: 20px auto 20px auto;
    border-radius: 10px;
    top: 30px;
    width: 50vw;
    overflow: auto;
    box-shadow: 0 0 10px #000;
    background-color:var(--bg-color);
    >h1{
        display: flex;
        padding: 15px 0 0 15px;
    }
    >.stack_card_desc{
        display: flex;
        height: 100%;
        padding-right: 15px;
        justify-content: center;
        align-items: center;
        >img{
            padding: 0 15px 0 20px;
        }
        >p{
            padding: 0 15px 0 0;
        }
    }
}

    @media (max-width: 992px) {
        .stack_card{
            height: 450px;
            >.stack_card_desc{
                flex-wrap: wrap;
                >p{
                    padding-left: 15px;
                }
            }
        }
    }

    @media (max-width: 762px){
        .stack_card{
            width: 100%;
        }
    }
