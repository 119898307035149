.formbg{
    background-color: #CCC;
    z-index: 1;
}
.formulaire{
    width: 60vw;
    padding: 0 5vw 15px 0;

}
.formulaire input, textarea{
    position:sticky;
    background-color: rgb(255, 255, 255, 0.1);
    border: black 1px solid;
    outline: none;
    height: 40px;
    width: 100%;
    margin-bottom: 30px;
    padding-left: 10px;
    position:block;
    z-index: 2;
    font-size: large;
    transition: all 0.5s;
}

.formulaire textarea{
    height: 20vh;
    resize: none;
}

.formulaire input:focus, textarea:focus, input:hover, textarea:hover{
    border: 1px solid var(--lightgrey);
}

.contactImg{
    position: sticky;
    height: 400px;
    margin-left: 0%;
    transition: all 0.5s;
}


.textareaError{
    position: absolute;
    padding-top: 22vh;
    z-index: 0;
}
.inputError{
    position: absolute;
    padding-top: 52px;
    z-index: 0;
}

.confirmationForm{
    position: absolute;
    text-align: center;
    width: 100%;
    padding: 10px 0 10px 0;
    margin-top: 40vh;
    background-color: lightgreen;
    animation: confform 0.5s cubic-bezier(.28,1.2,.94,1.21) 0.8s 1 both;
}
@keyframes confform {
    from {
      margin-left: 150%;
    }
  
    to {
      margin-left: 0%;
      transform: none;
    }
  }
@media only screen and (max-width: 576px) {
    .contactImg{
        display: none;
    }
}
@media only screen and (max-width: 992px) {
    .formulaire{
        width: 45vw;
        padding-right: none;
    }
}
@media only screen and (max-width: 800px) {
    .formulaire{
        width: 90vw;
        padding-right: none;
    }
}

