section{
    position: fixed;
    font-size: 40px;
	color: #FFF;
    text-align: center;
	background-color: var(--lightgrey);
	height: 100vh;
    width: 100vw;
	line-height: 100vh;
    display: block;
}

.section3{
	background-color: black;
	color: var(--text-color);
}

.loader{
	width: 50px;
	height: 50px;
	display: inline-block;
	vertical-align: middle;
	position: relative;
}
/*-----------------------------------*\
$Loader circles
\*-------------------------------------*/
.loadercircles{
    border-radius: 50px;
    border: 3px solid transparent;
    border-top-color: #b71540;
        -webkit-animation: spin 1s linear 0s infinite;
            animation: spin 1s linear 0s infinite;
    
    }
    
    .loadercircles:after{
        content: '';
        position: absolute;
        top: 4px; 
        left: 4px;
        bottom: 4px;
        right: 4px;
        border-radius: 50px;
        border: 3px solid transparent;
        border-top-color: #079992;
        opacity: 0.8;
        -webkit-animation: spin 5s linear 0s infinite;
                animation: spin 5s linear 0s infinite;
    }
    
    .loadercircles:before{
        content: '';
        position: absolute;
        top: 10px; 
        left: 10px;
        bottom: 10px;
        right: 10px;
        border-radius: 50px;
        border: 3px solid transparent;
        border-top-color: #f671e9;
        opacity: 0.5;
        -webkit-animation: spin 10s linear 0s infinite;
                animation: spin 10s linear 0s infinite;
    }
    
    @keyframes spin{
        0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
        100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    }