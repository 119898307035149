:root{
	--text-color: #FDFFFC;
	--bg-color:#E8E8E8;
	--orange: #996600;
	--lightgrey: #999999;
}


@font-face {
	font-display: fallback;
	font-family: "Alien Robot";
	src: local("Alien Robot"),
	 url("./components/assets/font/Alien Robot.ttf") format("truetype");
	}  

body{
	font-family: 'Alegreya', Arial;
	margin: 0px;
  	background-color:var(--bg-color);
	user-select: none;
}

h2{
	margin: 0px;
}
h1{
	margin: 0px;
}

.btn{
	background-color: transparent;
	border: 1px solid black;
	padding: 5px 10px 5px 10px;
}

.btn:hover{
	color: var(--lightgrey);
	border-color: 1px solid var(--lightgrey);
}

.btn:disabled{
	color: var(--lightgrey);
	border-color: 1px solid var(--lightgrey);
}

.btn:disabled::after{
	content: 'O';
}

.underline {
	max-width: 70px;
	bottom: -1rem;
	height: 0.5rem;
	z-index:2;
	background-image: url('../public/img/underline.svg');
	background-repeat: no-repeat;
	background-size: cover;
	color: var(--orange);
	// filter: grayscale(100%);
  }

/* positionnement des elements */

$gutter: 20px;

.container{
	display: flex;
	opacity: 0;
	width: 90vw;
	min-height: 100vh;
	margin-left: auto;
	margin-right: auto;
	
}
.container.show{
	animation: show 0.5s linear 0.5s both;
}
@keyframes show {
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}	
}

.container-min{
	min-height: auto;
}

.full-width{
	width: 100vw;
}


.typed-cursor{
	color:red;
	font-size: 3rem;
}

.card{
    display: flex;
	flex-wrap: wrap;
	width: 100%;
    justify-content: start;
    flex-direction: column;
    background-color: white;
	padding: 50px;
	margin-top: 60px;
	box-shadow: 0px 0px 10px #888888;
    >.card_body{
        display: flex;
		flex-wrap: nowrap;
        flex-direction: row;
		>.cardBody_top{
			display: flex;
		}
    }
}

.portfolio{
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 30px;
	>h1{
		text-transform: uppercase;
	}
}

.form{
	display: flex;
	margin-top: 30px;
}


@media (max-width: 576px) {
}
@media (max-width: 768px) {
	.card{
		box-shadow: none;
		background-color: transparent;
		}
}

@media (max-width: 992px) {
	.card{
		padding: 0;
		>.card_body{
			flex-wrap: wrap;
		}
	}
}

@media (max-width: 1200px) {
}

/*--------------------------------------------------------------
# perso lettre bump
--------------------------------------------------------------*/

.title, .home_title{
	display: flex;
	justify-content: center;
	text-transform: uppercase;
	cursor: default;
}

.title{
	margin-top: 30px;
	padding-top: 30px;
}

.home_title{
	font-family: 'Alien Robot', 'arial';
	color: #fff;
	margin:10px 0 20px 0;
}

.home_title .letterAfter{
	transition: all 0.5s ease-in-out;
	animation: textclip 0.5s linear 2;
}

.home_title .spacing{
	padding: 10px 10px 10px 10px;
}

.home_title .letter{
	padding: 10px 0px 10px 0px;
	letter-spacing: 3px;
}

@keyframes textclip {
	0%{
		padding: 10px 0px 10px 0px;
	}
	50%{
		padding: 5px 0px 15px 0px;
	}
	100%{
		padding: 10px 0px 10px 0px;
	}	
}