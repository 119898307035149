.border{
	width: 100%;
	height: 10px;
	border: 1px solid gray;
	background-color: transparent;
	>.progressBar{
		width: attr('data-width');
		background-color: #996600;
		box-shadow: 0px 0px 7px #996600;
		height: 100%;
	}
}